import { AppointmentType } from 'shared/enums/appointment-type.enum'


export const getLoadPickDate = (arriveByDate: Date, activityDate: Date, appointmentType: AppointmentType, warehouseHours: Date) => {
  // Custom logic that will replace the displayed pick start/end dates using the activity date instead in certain scenarios
  if (appointmentType === AppointmentType.APPOINTMENT_SET && arriveByDate && arriveByDate >= activityDate){
    return new Date(arriveByDate); // Use appointment date
  }

  let dateTime = new Date(activityDate);
  if (warehouseHours){
    dateTime?.setHours(warehouseHours.getHours( ));
    dateTime?.setMinutes(warehouseHours.getMinutes());
    dateTime?.setSeconds(0);
    dateTime?.setMilliseconds(0);
  }
  return dateTime;
}
