import { BaseModel } from 'shared/models/base.model';

export class AutoOpenSchedule extends BaseModel {
  action: string;
  autoOpenScheduleId: number;
  description: string;
  newValue: string;
  oldValue: string;
  scheduledDateTimeCentral: Date;
  completedDateTimeCentral?: Date;

  constructor(json?: AutoOpenScheduleJSON) {
    super(json);
    if (json) {
      this.action = json.action;
      this.autoOpenScheduleId = json.autoOpenScheduleId;
      this.description = json.description;
      this.newValue = json.newValue;
      this.oldValue = json.oldValue;
      this.scheduledDateTimeCentral = this.processDate(json.scheduledDateTimeCentral);

      if (json.completedDateTimeCentral) {
        this.completedDateTimeCentral = this.processDate(json.completedDateTimeCentral);
      }
    }
  }
}