import { BaseModel } from 'shared/models/base.model';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';

export class ReloadsResponse extends BaseModel implements ReloadsResponseJson {
  loads: AvailableLoadSummary[];
  totalLoadCount: number;
  reloadSearchRadius: number;
  compatibleWithReloads: boolean;

  constructor(response: ReloadsSearchResponseJSON) {
    super();
    this.loads = response?.loads ? response.loads.map(load => new AvailableLoadSummary(load)) : [];
    this.totalLoadCount = response.totalCount;
    this.compatibleWithReloads = response.compatibleWithReloads;
    this.reloadSearchRadius = response.reloadSearchRadius;
  }
}
