import { BaseModel } from 'shared/models/base.model';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';

export class AvailableLoadSummaryResponse extends BaseModel {
  results: AvailableLoadSummary[];
  performedSuggestedLoadSearch: boolean;

  constructor(response: AvailableShipmentResponseJSON, performedSuggestedLoadSearch?: boolean) {
    super();
    if (response.results) {
      this.results = response.results.map(load => new AvailableLoadSummary(load))
    } else {
      this.results = [];
    }
    this.performedSuggestedLoadSearch = performedSuggestedLoadSearch ?? false;
  }

  static fromReloadsResponse(response: ReloadsResponseJson,): AvailableLoadSummaryResponse {
    return new AvailableLoadSummaryResponse({
      results: response.loads,
    });
  }
}