import { BaseModel } from 'shared/models/base.model';

export class SearchHistoryLocations extends BaseModel<SearchHistoryLocationsJSON> {
  userId?: number;
  origins?: SearchHistoryLocationJSON[];
  destinations?: SearchHistoryLocationJSON[];

  constructor(json?: SearchHistoryLocationsJSON) {
    super(json);
    if (!json || !json.origins) {
      this.origins = [];
    }
    if (!json || !json.destinations) {
      this.destinations = [];
    }
  }

  static get maxLocations() {
    return 5;
  }

  private pushLocation(locations: SearchHistoryLocationJSON[], location: SearchHistoryLocationJSON) {
    const existingLocation = locations.find((o) => o.city === location.city && o.stateProvinceCode === location.stateProvinceCode && o.countryCode === location.countryCode);
    if (!existingLocation) {
      locations.unshift(location);
    }

    if (locations.length > SearchHistoryLocations.maxLocations) {
      locations.splice(SearchHistoryLocations.maxLocations);
    }
  }

  addOrigin(origin: SearchHistoryLocationJSON) {
    this.pushLocation(this.origins, origin);
  }

  addDestination(destination: SearchHistoryLocationJSON) {
    this.pushLocation(this.destinations, destination);
  }
}

export default SearchHistoryLocations;