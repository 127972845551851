import pickBy from 'lodash.pickby';
import { Inject, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import { AjaxError, AjaxRequest, AjaxTimeoutError } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { AvailableLoadSummaryResponse } from 'shared/models/loads/available-load-summary-response.model';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { AvailableLoadDetail } from 'shared/models/loads/available-load-detail.model';
import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { APIErrorResponse, ErrorType } from 'app/repositories/errors/api-error-response';
import { Http } from 'app/globals/constants';

const sourceSystemHeaderKey = 'Web-NavCarrier';

const filterCriteria = criteria =>
  pickBy(criteria, (value, key) => {
    if (key === 'mode' || key === 'specializedEquipmentCode') {
      return value !== EquipmentType.All;
    }

    return value !== '';
  });

interface DataWithRadius {
  originRadiusMiles: number;
  destinationRadiusMiles: number;
}

export const normalizeRadius = <T extends DataWithRadius>(data: T): T => {
  // API cannot accept floats for origin or destination radius,
  // but we need to internally maintain floats (elsewhere) for better conversion
  return {
    ...(data as any),
    originRadiusMiles: data.originRadiusMiles != null ? Math.round(data.originRadiusMiles) : null,
    destinationRadiusMiles: data.destinationRadiusMiles != null ? Math.round(data.destinationRadiusMiles) : null,
  };
};

const normalizeWeightAndDistance = (criteria: AvailableLoadSearchCriteriaJSON): AvailableLoadSearchCriteriaJSON => {
  return {
    ...criteria,
    weightMin: criteria.weightMin != null ? Math.round(criteria.weightMin) : null,
    weightMax: criteria.weightMax != null ? Math.round(criteria.weightMax) : null,
    milesMin: criteria.milesMin != null ? Math.round(criteria.milesMin) : null,
    milesMax: criteria.milesMax != null ? Math.round(criteria.milesMax) : null,
  };
};

const useActivityDates = (criteria: AvailableLoadSearchCriteriaJSON): AvailableLoadSearchCriteriaJSON => {
  const newCriteria = {
    ...criteria,
    activityStart: criteria.pickupStart,
    activityEnd: criteria.pickupEnd,
  };
  delete newCriteria.pickupStart;
  delete newCriteria.pickupEnd;
  return newCriteria;
};

@Service()
export class ShipmentsRepository extends CacheableRepository {
  @Inject('apiConfig.shipmentsAPI')
  protected baseUrl;

  getLoad(loadNumber: number): Observable<AvailableLoadDetail> {
    return this.get(`/v1/shipments/${loadNumber}`)
      .map(json => new AvailableLoadDetail(json));
  }

  searchShipments(criteria: AvailableLoadSearchCriteriaJSON, correlationId?: string, captchaToken?: string): Observable<AvailableLoadSummaryResponse> {
    criteria = normalizeWeightAndDistance(normalizeRadius(filterCriteria(criteria)));
    criteria = useActivityDates(criteria);
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-SourceSystem': sourceSystemHeaderKey,
      'X-Captcha-Token': captchaToken,
    };
    const ajaxRequest: AjaxRequest = {
      url: '/v1/shipments/search',
      method: Http.POST,
      body: criteria,
      timeout: 10000,
      headers: headers,
    };

    return this.postWithRequest(ajaxRequest)
      .catch((error: AjaxError | AjaxTimeoutError) => {
        if (error instanceof AjaxTimeoutError) {
          error.response = { type: ErrorType.TimeOutFailure };
          return Observable.throw(new APIErrorResponse(error.response, 408));
        }
        return Observable.throw(new APIErrorResponse(error.response, error.status));
      })
      .map(loads => new AvailableLoadSummaryResponse(loads, false));
  }

  searchSuggestedShipments(correlationId: string, isStfBookable?: boolean, captchaToken?: string): Observable<AvailableLoadSummaryResponse> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    let searchCriteria: AvailableLoadSearchCriteriaJSON = {};
    if (isStfBookable) {
      searchCriteria.isStfBookable = true;
    }

    return this.post('/v1/shipments/search', searchCriteria, headers)
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .map(loads => new AvailableLoadSummaryResponse(loads, true));
  }

  searchByLoadNumber(criteria: AvailableLoadSearchCriteriaJSON, captchaToken?: string): Observable<AvailableLoadSummaryResponse> {
    const headers: any = {
      'X-Captcha-Token': captchaToken,
    };

    let searchCriteria: AvailableLoadSearchCriteriaJSON = { loadNumber: criteria.loadNumber };
    if (criteria.isStfBookable) {
      searchCriteria.isStfBookable = true;
    }

    return this.post('/v1/shipments/search', searchCriteria, headers)
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .map(loads => new AvailableLoadSummaryResponse(loads, false));
  }
}
