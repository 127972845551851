import isEqual from 'lodash.isequal';

import { FormattableAddress } from 'shared/components/formatters/city-state-country.formatter';
import { EquipmentType } from 'shared/enums/equipment-type.enum';

import { BaseModel } from '../base.model';

export class AvailableLoadSearchHistory extends BaseModel<AvailableLoadSearchHistoryJSON> implements IAvailableLoadSearchHistory {
  private searchId: number;
  userId: number;
  originCity: string;
  originStateCode: string;
  originCountryCode: string;
  originStateList: string;
  originRadiusMiles: number;
  destinationCity: string;
  destinationStateCode: string;
  destinationCountryCode: string;
  destinationStateList: string;
  destinationRadiusMiles: number;
  equipmentType: EquipmentType;
  specializedEquipmentType: string;
  minTotalWeight: number;
  maxTotalWeight: number;
  minTotalDistance: number;
  maxTotalDistance: number;
  pageSize: number;
  searchDate: Date;
  lastUsedDate: Date;
  private favorited: boolean;
  scheduled: boolean;
  lastNotificationSentDate: Date;

  get isPreferredLane() {
    return this.favorited;
  }
  set isPreferredLane(isPreferred) {
    this.favorited = isPreferred;
  }

  static fromSearchCriteria(criteria: AvailableLoadSearchCriteriaJSON) {
    return criteria ? new AvailableLoadSearchHistory({
      originCity: criteria.originCity,
      originStateCode: criteria.originStateProvinceCode,
      originCountryCode: criteria.originCountryCode,
      originStateList: (criteria.originStateCodes || []).toString(),
      originRadiusMiles: criteria.originRadiusMiles,
      destinationCity: criteria.destinationCity,
      destinationStateCode: criteria.destinationStateProvinceCode,
      destinationCountryCode: criteria.destinationCountryCode,
      destinationStateList: (criteria.destinationStateCodes || []).toString(),
      destinationRadiusMiles: criteria.destinationRadiusMiles,
      equipmentType: criteria.mode as EquipmentType,
      specializedEquipmentType: criteria.specializedEquipmentCode,
      minTotalWeight: criteria.weightMin,
      maxTotalWeight: criteria.weightMax,
      minTotalDistance: criteria.milesMin,
      maxTotalDistance: criteria.milesMax,
      // search and last used dates are expected to be provided from the client
      // these dates are used for evicting old search history, and searches with null
      // dates are immediately evicted from the database
      searchDate: new Date(),
      lastUsedDate: new Date(),
      favorited: false,
      scheduled: false
    }) : null;
  }

  constructor(data?: AvailableLoadSearchHistoryJSON) {
    super(data);

    this.favorited = Boolean(this.favorited);
    this.scheduled = Boolean(this.scheduled);

    this.searchDate = this.processDate(this.searchDate);
    this.lastUsedDate = this.processDate(this.lastUsedDate);
    this.lastNotificationSentDate = this.processDate(this.lastNotificationSentDate);

    this.originCity = this.originCity || '';
    this.originStateCode = this.originStateCode || '';
    this.originCountryCode = this.originCountryCode || '';
    this.originStateList = this.originStateList || '';
    this.destinationCity = this.destinationCity || '';
    this.destinationStateCode = this.destinationStateCode || '';
    this.destinationCountryCode = this.destinationCountryCode || '';
    this.destinationStateList = this.destinationStateList || '';

    this.equipmentType = this.equipmentType || EquipmentType.All;

    this.originRadiusMiles = this.originRadiusMiles != null ? Number(this.originRadiusMiles) : this.originRadiusMiles;
    this.destinationRadiusMiles = this.destinationRadiusMiles != null ? Number(this.destinationRadiusMiles) : this.destinationRadiusMiles;
    this.minTotalWeight = this.minTotalWeight != null ? Number(this.minTotalWeight) : this.minTotalWeight;
    this.maxTotalWeight = this.maxTotalWeight != null ? Number(this.maxTotalWeight) : this.maxTotalWeight;
    this.minTotalDistance = this.minTotalDistance != null ? Number(this.minTotalDistance) : this.minTotalDistance;
    this.maxTotalDistance = this.maxTotalDistance != null ? Number(this.maxTotalDistance) : this.maxTotalDistance;
  }

  toJson(): AvailableLoadSearchHistoryJSON {
    return Object.assign(super.toJson(), {
      searchDate: this.convertDateToDateString(this.searchDate),
      lastUsedDate: this.convertDateToDateString(this.lastUsedDate),
      lastNotificationSentDate: this.convertDateToDateString(this.lastNotificationSentDate),
    });
  }

  toSearchCriteria(isABTesting?: boolean, state?: any): AvailableLoadSearchCriteriaJSON {
    return {
      originCity: this.originCity,
      originStateProvinceCode: this.originStateCode,
      originCountryCode: this.originCountryCode,
      originStateCodes: this.originStateList && this.originStateList.split(','),
      originRadiusMiles: this.originRadiusMiles,
      destinationCity: this.destinationCity,
      destinationStateProvinceCode: this.destinationStateCode,
      destinationCountryCode: this.destinationCountryCode,
      destinationStateCodes: this.destinationStateList && this.destinationStateList.split(','),
      destinationRadiusMiles: this.destinationRadiusMiles,
      mode: this.equipmentType,
      specializedEquipmentCode: this.specializedEquipmentType,
      weightMin: this.minTotalWeight,
      weightMax: this.maxTotalWeight,
      milesMin: this.minTotalDistance,
      milesMax: this.maxTotalDistance,
      pageSize: isABTesting ? 250 : 5000
    };
  }

  equals(entry: AvailableLoadSearchHistory) {
    return isEqual(entry.toSearchCriteria(), this.toSearchCriteria());
  }

  protected convertDateToDateString(date?: Date) {
    return date ? super.convertDateToDateString(date) : null;
  }

  isMultiSearch() {
    return Boolean(this.originStateList || this.destinationStateList);
  }

  get id() {
    return this.searchId;
  }

  get origin(): FormattableAddress {
    return {
      city: this.originCity,
      stateCode: this.originStateCode || this.originStateList.split(',').join(', '),
      countryCode: this.originCountryCode
    };
  }

  get destination(): FormattableAddress {
    return {
      city: this.destinationCity,
      stateCode: this.destinationStateCode || this.destinationStateList.split(',').join(', '),
      countryCode: this.destinationCountryCode
    };
  }

  get equipment(): EquipmentType {
    return this.equipmentType;
  }
}
