export enum OfferStatus {
  UNKNOWN,
  PENDING,
  ACCEPTED,
  REVOKED,
  REJECTED,
  NO_LONGER_AVAILABLE,
  EXPIRED,
  UNABLE_TO_PROCESS,
  ERROR,
  COUNTERED,
  NOT_CONSIDERED,
  OPEN,
  CLOSED,
  MAXIMUM_NUMBER_OF_OFFERS_SUBMITTED,
}
