import pickBy from 'lodash.pickby';
import { Inject, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { AvailableLoadSummaryResponse } from 'shared/models/loads/available-load-summary-response.model';
import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { AvailableLoadSearchHistory } from 'shared/models/loads/available-load-search-history.model';
import { SearchHistoryLocations } from 'shared/models/recent-searches/search-history-locations.model';
import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { APIErrorResponse, ErrorType } from 'app/repositories/errors/api-error-response';
import { Offer } from 'shared/models/offers/offer.model';
import { Util } from 'app/util/util';
import { OfferStatus } from 'shared/enums/offer-status.enum';
import { ResultSet } from 'shared/models/result-set.model';
import { ReloadsResponse } from 'shared/models/loads/reloads-response.model';
import { of } from 'rxjs';
import SearchHistoryLocationsCollection from '@shared/models/recent-searches/search-history-locations-collection.model';
const sourceSystemHeaderKey = 'Web-NavCarrier';
const locationSearchHistoryKey = 'search-history-locations';

const filterCriteria = criteria => pickBy(criteria, (value, key) => {
  if (key === 'mode' || key === 'specializedEquipmentCode') {
    return value !== EquipmentType.All;
  }

  return value !== '';
});

interface DataWithRadius {
  originRadiusMiles: number;
  destinationRadiusMiles: number;
}

interface SearchLocationsPayload {
  existingLocationsCollectionJson: string;
  existingLocationsCollection: SearchHistoryLocationsCollection;
  userLocations: SearchHistoryLocations;
}

interface SearchedLocations {
  origin: SearchHistoryLocationJSON;
  destination: SearchHistoryLocationJSON;
}

export const normalizeRadius = <T extends DataWithRadius>(data: T): T => {
  // API cannot accept floats for origin or destination radius,
  // but we need to internally maintain floats (elsewhere) for better conversion
  return {
    ...data as any,
    originRadiusMiles: (data.originRadiusMiles != null) ? Math.round(data.originRadiusMiles) : null,
    destinationRadiusMiles: (data.destinationRadiusMiles != null) ? Math.round(data.destinationRadiusMiles) : null,
  };
};

const normalizeWeightAndDistance = (criteria: AvailableLoadSearchCriteriaJSON): AvailableLoadSearchCriteriaJSON => {
  return {
    ...criteria,
    weightMin: (criteria.weightMin != null) ? Math.round(criteria.weightMin) : null,
    weightMax: (criteria.weightMax != null) ? Math.round(criteria.weightMax) : null,
    milesMin: (criteria.milesMin != null) ? Math.round(criteria.milesMin) : null,
    milesMax: (criteria.milesMax != null) ? Math.round(criteria.milesMax) : null
  };
};

const useActivityDates = (criteria: AvailableLoadSearchCriteriaJSON): AvailableLoadSearchCriteriaJSON => {
  const newCriteria = {
    ...criteria,
    activityStart: criteria.pickupStart,
    activityEnd: criteria.pickupEnd
  };
  delete newCriteria.pickupStart;
  delete newCriteria.pickupEnd;
  return newCriteria;
};

@Service()
export class AvailableLoadsRepository extends CacheableRepository {
  @Inject('apiConfig.availableLoadsAPI')
  protected baseUrl;

  search(criteria: AvailableLoadSearchCriteriaJSON): Observable<AvailableLoadSummaryResponse> {
    criteria = normalizeWeightAndDistance(normalizeRadius(filterCriteria(criteria)));
    return this.post('/Loads/Search', criteria)
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .map(loads => new AvailableLoadSummaryResponse({ results: loads }));
  }

  getReloads(request: ReloadsSearchCriteriaJSON, correlationId: string): Observable<ReloadsResponse> {
    return this.getReloadsInternal(request, correlationId, response =>
      new ReloadsResponse(response));
  }

  // returns AvailableLoadSummaryResponse to make reloads compatible with find loads page.
  getReloadsForViewAll(
    request: ReloadsSearchCriteriaJSON,
    correlationId: string
  ): Observable<AvailableLoadSummaryResponse> {
    return this.getReloadsInternal(request, correlationId, response =>
      new AvailableLoadSummaryResponse({ results: response.loads }));
  }

  private getReloadsInternal<TResponse>(
    request: ReloadsSearchCriteriaJSON,
    correlationId: string,
    mapResponse: (r: any) => TResponse
  ): Observable<TResponse> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-SourceSystem': sourceSystemHeaderKey,
    };

    if (!!request.criteria) {
      let criteria = normalizeWeightAndDistance(normalizeRadius(filterCriteria(request.criteria)));
      criteria = useActivityDates(criteria);
      request.criteria = criteria;
    }
    return this.post('/Reloads', request, correlationId ? headers : null)
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .map(mapResponse);
  }

  private getSearchHistoryLocationsFromLocalStorage(userId: number): SearchLocationsPayload {
    const existingLocations = localStorage.getItem(locationSearchHistoryKey);
    let existingLocationsCollection: SearchHistoryLocationsCollection = null;
    let locations: SearchHistoryLocations = null;

    if (existingLocations) {
      existingLocationsCollection = new SearchHistoryLocationsCollection(JSON.parse(existingLocations));
      const userLocations = existingLocationsCollection.searchHistoryLocationsCollection.find((o: SearchHistoryLocations) => o.userId === userId);

      if (userLocations) {
        locations = userLocations;
      } else {
        locations = new SearchHistoryLocations({ userId: userId });
      }
    } else {
      locations = new SearchHistoryLocations({ userId: userId });
      existingLocationsCollection = new SearchHistoryLocationsCollection();
    }
    return {
      existingLocationsCollectionJson: existingLocations,
      existingLocationsCollection: existingLocationsCollection,
      userLocations: locations
    }
  }

  private GetSearchedLocations(searchCriteria: AvailableLoadSearchCriteriaJSON): SearchedLocations {
    let origin: SearchHistoryLocationJSON = null;
    let destination: SearchHistoryLocationJSON = null;

    if (searchCriteria.originCity &&
      searchCriteria.originStateProvinceCode &&
      searchCriteria.originLatitude &&
      searchCriteria.originLongitude &&
      searchCriteria.originCountryCode &&
      searchCriteria.originCountryName) {
      origin = {
        city: searchCriteria.originCity,
        stateProvinceCode: searchCriteria.originStateProvinceCode,
        latitude: searchCriteria.originLatitude,
        longitude: searchCriteria.originLongitude,
        countryCode: searchCriteria.originCountryCode,
        countryName: searchCriteria.originCountryName,
        exampleZipCode: searchCriteria.originExampleZipCode
      }
    }

    if (searchCriteria.destinationCity &&
      searchCriteria.destinationStateProvinceCode &&
      searchCriteria.destinationLatitude &&
      searchCriteria.destinationLongitude &&
      searchCriteria.destinationCountryCode &&
      searchCriteria.destinationCountryName) {
      destination = {
        city: searchCriteria.destinationCity,
        stateProvinceCode: searchCriteria.destinationStateProvinceCode,
        latitude: searchCriteria.destinationLatitude,
        longitude: searchCriteria.destinationLongitude,
        countryCode: searchCriteria.destinationCountryCode,
        countryName: searchCriteria.destinationCountryName,
        exampleZipCode: searchCriteria.destinationExampleZipCode
      }
    }

    return {
      origin: origin,
      destination: destination
    }
  }

  saveSearchHistoryLocations(userId: number, searchCriteria: AvailableLoadSearchCriteriaJSON) {
    if (!userId || !searchCriteria) {
      return;
    }

    const searchedLocations = this.GetSearchedLocations(searchCriteria);
    const locationPayload = this.getSearchHistoryLocationsFromLocalStorage(userId);
    const { existingLocationsCollectionJson, existingLocationsCollection, userLocations: locations } = locationPayload;

    if (searchedLocations.origin != null) {
      locations.addOrigin(searchedLocations.origin);
    }
    if (searchedLocations.destination != null) {
      locations.addDestination(searchedLocations.destination);
    }

    existingLocationsCollection.pushSearchHistoryLocations(locations);
    const newLocationCollection = JSON.stringify(existingLocationsCollection.searchHistoryLocationsCollection);

    if (existingLocationsCollectionJson !== newLocationCollection) {
      localStorage.setItem(locationSearchHistoryKey, newLocationCollection);
    }
  }

  // V2 - using local storage
  getSearchHistoryLocations(userId: number): Observable<SearchHistoryLocations> {
    if (!userId) {
      return of(new SearchHistoryLocations());
    }

    const locationsPayload = this.getSearchHistoryLocationsFromLocalStorage(userId);
    return of(locationsPayload.userLocations);
  }

  // V1
  getSearchHistory(): Observable<AvailableLoadSearchHistory[]> {
    return this.get('/AvailableLoadSearches')
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .map(searches => searches.map(search => new AvailableLoadSearchHistory(search)))
      .map(searches => searches.sort((a, b) => a.searchDate - b.searchDate));
  }

  saveSearchHistory(entry: AvailableLoadSearchHistory) {
    const data = normalizeRadius(entry.toJson());
    const observable = entry.id
      ? this.put(`/AvailableLoadSearches/${entry.id}`, data)
      : this.post('/AvailableLoadSearches', data);

    return observable
      .map(json => new AvailableLoadSearchHistory(json));
  }

  deleteSearchHistory(entry: AvailableLoadSearchHistory) {
    return this.delete(`/AvailableLoadSearches/${entry.id}`);
  }

  clearSearchHistory() {
    return this.delete('/AvailableLoadSearches');
  }

  getOffers(): Observable<ResultSet<Offer>> {
    return this.get('/Offers')
      // wrap un-paginated offers response as a paginated response that contains all results
      // @todo: this can be removed once the API has finished adding paginated response on offers
      .map(response => response.results ? response : { results: response, totalRecords: response.length })
      // sort offers from newest to oldest to make finding the latest offer easier
      .map(response => {
        response.results.sort(Util.sortByField('offerId', { reverse: true }));

        return response;
      })
      .map(response => new ResultSet(response, offer => new Offer(offer)));
  }

  saveOffer(offer: Offer): Observable<Offer> {
    const data = offer.toJson();
    const observable = !offer.offerId
      ? this.post('/Offers', data)
      : this.put(`/Offers/${offer.offerId}`, data);
    return observable
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .map(response => new Offer(response));
  }

  saveOfferV2(offer: OfferJSON): Observable<Offer> {
    // new route accepts only new offers - without offerId. Calling API with offerId will return an error
    const observable = this.post('/Offers/v2', offer);
    return observable
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .map(response => new Offer(response));
  }

  saveCounterOffer(offer: OfferJSON): Observable<Offer> {
    // new route accepts only new offers - without offerId. Calling API with offerId will return an error
    const observable = this.post('/Offers/RejectAndPost', offer);
    return observable
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .map(response => new Offer(response));
  }

  acceptOffer(offerId: number): Observable<void> {
    return this.patch(`/Offers/${offerId}/Accept`, {})
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)));
  }

  revokeOffer(offer: Offer): Observable<void> {
    offer.displayStatus = OfferStatus.REVOKED;
    return this.put(`/Offers/${offer.offerId}`, offer.toJson());
  }
}
