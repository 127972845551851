import { SearchHistoryLocations } from 'shared/models/recent-searches/search-history-locations.model';

export class SearchHistoryLocationsCollection {
  searchHistoryLocationsCollection: Array<SearchHistoryLocations>;

  constructor(models?: Array<SearchHistoryLocations>) {
    this.searchHistoryLocationsCollection = [];

    if (models) {
      models.forEach((model) => {
        if (model instanceof SearchHistoryLocations) {
          this.searchHistoryLocationsCollection.push(model);
        } else {
          this.searchHistoryLocationsCollection.push(new SearchHistoryLocations(model));
        }
      });
    }
  }

  static get maxLocations() {
    return 5;
  }

  pushSearchHistoryLocations(location: SearchHistoryLocations) {
    if (!location.userId) {
      return;
    }

    const existingSearchHistoryLocations = this.searchHistoryLocationsCollection.find((o) => o.userId === location.userId);
    if (existingSearchHistoryLocations) {
      existingSearchHistoryLocations.origins = location.origins;
      existingSearchHistoryLocations.destinations = location.destinations;
    } else {
      this.searchHistoryLocationsCollection.unshift(location);
    }

    if (this.searchHistoryLocationsCollection.length > SearchHistoryLocationsCollection.maxLocations) {
      this.searchHistoryLocationsCollection.splice(SearchHistoryLocations.maxLocations);
    }
  }
}

export default SearchHistoryLocationsCollection;