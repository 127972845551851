import { BaseModel } from 'shared/models/base.model';
import { Warehouse } from 'shared/models/warehouse.model';
import { StopType } from 'shared/enums/stop-type.enum';
import { AppointmentType } from 'shared/enums/appointment-type.enum';

export class AvailableLoadStop extends BaseModel {
  appointmentType: AppointmentType;
  arriveByStartDateTime?: Date;
  arriveByEndDateTime?: Date;
  sequenceNumber: number;
  stopNumber: number;
  stopType: StopType;
  warehouse: Warehouse;

  constructor(json?: AvailableLoadStopJSON) {
    super(json);
    if (json) {
      this.arriveByStartDateTime = this.processDate(json.arriveByStartDateTime);
      this.arriveByEndDateTime = this.processDate(json.arriveByEndDateTime);

      if (json.warehouse) {
        this.warehouse = new Warehouse(json.warehouse);
      }
    }
  }

  toJson(): AvailableLoadStopJSON {
    return {
      appointmentType: this.appointmentType,
      arriveByEndDateTime: this.convertDateToDateString(this.arriveByEndDateTime),
      arriveByStartDateTime: this.convertDateToDateString(this.arriveByEndDateTime),
      sequenceNumber: this.sequenceNumber,
      stopNumber: this.stopNumber,
      stopType: this.stopType,
      warehouse: this.warehouse.toJson(),
    };
  }

  isPickup(): boolean {
    return this.stopType === StopType.PICKUP;
  }

  isDropOff(): boolean {
    return this.stopType === StopType.DROP_OFF;
  }
}
