import { Action } from 'redux';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { Offer } from 'shared/models/offers/offer.model';
import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';
import { OfferStatus } from 'shared/enums/offer-status.enum';
import AvailableLoadOfferRejectionReason from '@shared/enums/available-load-offer-rejection-reason';

export const FETCH_OFFERS = `${REQUEST_START}:FETCH_OFFERS`;
export const FETCH_OFFERS_SUCCESS = `${REQUEST_SUCCESS}:FETCH_OFFERS`;
export const FETCH_OFFERS_FAILURE = `${REQUEST_FAILURE}:FETCH_OFFERS`;
export const STORE_NEW_OFFER = `${REQUEST_SUCCESS}:STORE_NEW_OFFER`;
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const UPDATE_EU_OFFER = 'UPDATE_EU_OFFER';
export const UPDATE_OFFER_STATUS_ON_OFFERS_STORE = 'UPDATE_OFFER_STATUS_ON_OFFERS_STORE';
export const UPDATE_OFFER_STATUS_FROM_SIGNALR = 'UPDATE_OFFER_STATUS_FROM_SIGNALR';
export const ACCEPT_COUNTERED_OFFER_FROM_OFFERS = "ACCEPT_COUNTERED_OFFER_FROM_OFFERS";
export const REJECT_COUNTERED_OFFER_FROM_OFFERS = "REJECT_COUNTERED_OFFER_FROM_OFFERS";

export const fetchOffers = () => ({type: FETCH_OFFERS});
export const fetchOffersSuccess = (payload: Offer[]) => ({type: FETCH_OFFERS_SUCCESS, payload});
export const fetchOffersFailure = (error: AjaxError) => ({type: FETCH_OFFERS_FAILURE, error});
export const storeNewOffer = (payload: Offer) => ({type: STORE_NEW_OFFER, payload: [payload]});
export const updateOffer = (payload: UpdateOffer) => ({ type: UPDATE_OFFER, payload });
export const updateEUOffer = (payload: Offer) => ({ type: UPDATE_EU_OFFER, payload });
export const updateOfferStatusFromSignalR = (payload: OfferStatusUpdateParameter) => ({ type: UPDATE_OFFER_STATUS_FROM_SIGNALR, payload });
export const updateOfferStatusOnOffersStore = (payload: OfferStatusUpdateParameter) => ({ type: UPDATE_OFFER_STATUS_ON_OFFERS_STORE, payload });
export const acceptCounteredOfferFromOffers = (acceptOffer: AcceptOffer) => ({ type: ACCEPT_COUNTERED_OFFER_FROM_OFFERS, payload: acceptOffer });
export const rejectCounteredOfferFromOffers = (rejectOffer: RejectOffer) => ({ type: REJECT_COUNTERED_OFFER_FROM_OFFERS, payload: rejectOffer });

export const REFRESH_OFFERS = `REFRESH_OFFERS`;

export const refreshOffers = () => ({type: REFRESH_OFFERS});

export const REFRESH_OFFERS_ON_WINDOW = 'REFRESH_OFFERS_ON_WINDOW';
export const refreshOffersOnWindow = (target: Window) => ({type: REFRESH_OFFERS_ON_WINDOW, target});

export interface RefreshOffersOnWindowAction extends Action {
  target: Window;
}

export interface OfferStatusUpdateParameter {
  loadNumber: number;
  resultReceivedDate?: Date;
  price?: number;
  offerId?: number;
  currencyCode?: string;
  updatedStatus: OfferStatus;
  offerRequestId?: string;
  isFinalNegotiation?: boolean;
  rejectionReason?: string;
}

export interface UpdateOffer {
  offerId: number;
  updatedStatus: OfferStatus;
}

export interface AcceptOffer {
  offerId: number;
  loadNumber: number;
}

export interface RejectOffer {
  offerId: number;
  loadNumber: number;
}
