import { BaseModel } from 'shared/models/base.model';

export class BaseCommodity extends BaseModel {
  description: string;
  orderId: number;
  commodityNumber: number;

  pallets?: number;
  weight?: number;
  pieces?: number;
  confirmedBy?: string;

  private isHazmat: boolean;

  get isHazMat(): boolean {
    return this.isHazmat;
  }
  set isHazMat(value: boolean) {
    this.isHazmat = value;
  }

  constructor(json?: CommodityJSON|ExtendedCommodityJSON) {
    super(json);
  }

  toJson(): CommodityJSON {
    return Object.assign({}, this) as any;
  }
}
