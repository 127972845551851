import { BaseModel } from 'shared/models/base.model';
import { Warehouse } from 'shared/models/warehouse.model';
import { StopType } from 'shared/enums/stop-type.enum';
import { AppointmentType } from 'shared/enums/appointment-type.enum';
import { DriverWork } from 'shared/enums/driver-work.enum';
import { BaseCommodity } from 'shared/models/loads/commodities/base-commodity.model';

export class Stop extends BaseModel {
  number: number;
  sequenceNumber: number;
  stopType: StopType;
  appointmentType: AppointmentType;
  appointmentStart: Date;
  appointmentEnd: Date;
  hasWork: boolean;
  driverWorkCode: DriverWork;
  warehouse: Warehouse;
  arrivalDateTime: Date;
  departureDateTime: Date;
  arrivedBy: string;
  completedBy: string;
  bolNumber: string;
  sealNumber: string;
  podNumber: string;
  commodities: BaseCommodity[];
  actualWeight: number;
  maxWeight: number;
  recordedTemperature: number;
  calculatedArriveByStartDateTime?: Date;
  calculatedArriveByEndDateTime?: Date;

  private refNum;

  get referenceNumber() {
    return this.refNum;
  }
  set referenceNumber(value) {
    this.refNum = value;
  }

  constructor(json?: StopJSON) {
    super(json);
    if (json) {
      this.appointmentStart = this.processDate(json.appointmentStart);
      this.appointmentEnd = this.processDate(json.appointmentEnd);
      this.calculatedArriveByStartDateTime = this.processDate(json.calculatedArriveByStartDateTime);
      this.calculatedArriveByEndDateTime = this.processDate(json.calculatedArriveByEndDateTime);

      if (json.warehouse) {
        this.warehouse = new Warehouse(json.warehouse);
        this.warehouse.directions = json.specialInstructionText;
      }
      if (json.commodities && Array.isArray(json.commodities)) {
        this.commodities = json.commodities.map(data => new BaseCommodity(data));
      }

      if (json.arrived) {
        this.arrivalDateTime = this.processDate(json.arrived);
      }
      if (json.departed) {
        this.departureDateTime = this.processDate(json.departed);
      }
    }
  }

  toJson(): StopJSON {
    return {
      refNum: this.refNum,
      appointmentEnd: this.convertDateToDateString(this.appointmentEnd),
      appointmentStart: this.convertDateToDateString(this.appointmentStart),
      calculatedArriveByEndDateTime: this.convertDateToDateString(this.calculatedArriveByEndDateTime),
      calculatedArriveByStartDateTime: this.convertDateToDateString(this.calculatedArriveByEndDateTime),
      warehouse: this.warehouse.toJson(),
      specialInstructionText: this.warehouse.directions,
      commodities: this.commodities?.map(item => item.toJson()),
      actualWeight: this.actualWeight,
      maxWeight: this.maxWeight,
      arrived: this.convertDateToDateString(this.arrivalDateTime),
      departed: this.convertDateToDateString(this.departureDateTime),
      number: this.number,
      sequenceNumber: this.sequenceNumber,
      stopType: this.stopType,
      appointmentType: this.appointmentType,
      hasWork: this.hasWork,
      driverWorkCode: this.driverWorkCode,
      bolNumber: this.bolNumber,
      podNumber: this.podNumber,
      sealNumber: this.sealNumber,
      recordedTemperature: this.recordedTemperature?.toString(),
      arrivedBy: this.arrivedBy,
      completedBy: this.completedBy
    };
  }

  isPickup(): boolean {
    return this.stopType === StopType.PICKUP;
  }

  isDropOff(): boolean {
    return this.stopType === StopType.DROP_OFF;
  }

  isFirstStop(): boolean {
    return this.number === 0;
  }

  getTotalItemWeight(): number {
    if (this.commodities) {
      return this.commodities.reduce((sum, item) => sum + item.weight, 0);
    }
  }
}
